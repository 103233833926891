 import firebase from 'firebase/app'
 import 'firebase/firestore'
 import 'firebase/auth'
 
 // Your web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyDR7osa2IJt8E1Szpusuni9iaU7JZSs1_g",
    authDomain: "highway11-marioplan.firebaseapp.com",
    databaseURL: "https://highway11-marioplan.firebaseio.com",
    projectId: "highway11-marioplan",
    storageBucket: "highway11-marioplan.appspot.com",
    messagingSenderId: "425978113963",
    appId: "1:425978113963:web:a32a5b6757c4db8e96ecee"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  //firebase.firestore().settings({ timestampsInSnapshots: true})


  export default firebase;